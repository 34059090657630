function rewriteUrl(url) {
  if (url.linktype !== "story") return url.cached_url;

  let new_url = url.cached_url;

  new_url = new_url.replace("home", "");

  if (url.anchor) new_url = `${new_url}#${url.anchor}`;

  if (new_url.charAt(0) !== "/") new_url = `/${new_url}`;

  if (process.env.NODE_ENV === "production") {
    new_url = `https://www.brightsignglove.com${new_url}`;
  } else if (process.env.NODE_ENV === "development") {
    new_url = `https://localhost:8000${new_url}`;
  }

  return new_url;
}

// es5 export because of import into gatsby-node.js
module.exports = rewriteUrl;
