import { Helmet } from 'react-helmet';
import Picture from './Picture';
import React from 'react';

const Layout = ({ children, location }) => {
  const { pathname } = location;
  const language = pathname.split('/')[1].replace('/', '');
  const activeLanguage = ['ar', 'en'].includes(language) ? language : 'en';
  const activeDirection = ['ar'].includes(activeLanguage) ? 'rtl' : 'ltr';

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: activeLanguage,
          dir: activeDirection,
        }}
      />
      <div className="w-full min-h-screen bg-banner">
        <div className="flex flex-row justify-center bg-white p-4 mb-8">
          <Picture
            src="/img/logo.png"
            alt="BrightSign Logo"
            width={120}
          ></Picture>
        </div>
        <main className="max-w-lg m-auto">{children}</main>
      </div>
    </>
  );
};

export default Layout;
