import { useEffect, useRef, useState } from 'react';

import StoryblokClient from 'storyblok-js-client';
import config from '../../gatsby-config';

const sbConfig = config.plugins.find(
  (item) => item.resolve === 'gatsby-source-storyblok'
);

const sbClient = new StoryblokClient({
  accessToken: sbConfig.options.accessToken,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export default function useStoryblok(originalStory, location) {
  let [story, _setStory] = useState(originalStory);

  const storyRef = useRef(story);
  const setStory = (data) => {
    storyRef.current = data;
    _setStory(data);
  };

  if (story && typeof story.content === 'string') {
    story.content = JSON.parse(story.content);
  }

  // see https://www.storyblok.com/docs/Guides/storyblok-latest-js
  function initEventListeners() {
    const { StoryblokBridge } = window;

    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge({
        preventClicks: true,
        resolveRelations: [],
      });

      storyblokInstance.on(['published', 'change'], (event) => {
        // reload project on save an publish
        window.location.reload(true);
      });

      storyblokInstance.on('input', (event) => {
        // live updates when editing
        if (event.story.content._uid === storyRef.current.content._uid) {
          setStory(event.story);
        }
      });

      storyblokInstance.on('enterEditmode', (event) => {
        // loading the draft version on initial view of the page
        const urlParams = new URLSearchParams(location.search);
        const sbLang = urlParams.get('_storyblok_lang');

        sbClient
          .get(`cdn/stories/${event.storyId}`, {
            language: sbLang,
            version: 'draft',
            resolve_relations: [],
          })
          .then(({ data }) => {
            if (data.story) {
              setStory(data.story);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  function addBridge(callback) {
    // check if the script is already present
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`;
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        // call a function once the bridge is loaded
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    // load bridge only inside the storyblok editor
    if (location.search.includes('_storyblok')) {
      // first load the bridge and then attach the events
      addBridge(initEventListeners);
    }
  }, []); // it's important to run the effect only once to avoid multiple event attachment

  return story;
}
