import DynamicComponent from './DynamicComponent';
import React from 'react';

const LinkBlock = ({ blok }) => {
  return (
    <div>
      <h2 className="text-2xl text-white border-b">{blok.title}</h2>
      <div className="flex flex-col space-y-2">
        {blok.links.map((link) => (
          <DynamicComponent blok={link}></DynamicComponent>
        ))}
      </div>
    </div>
  );
};

export default LinkBlock;
