import {
  MARK_LINK,
  NODE_HEADING,
  NODE_PARAGRAPH,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer';

import DynamicComponent from './DynamicComponent';
import React from 'react';
import rewriteUrl from '../utils/rewriteUrl';

const RichText = ({ document, className }) => {
  const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

  return (
    <div className="space-y-4">
      {render(document, {
        markResolvers: {
          [MARK_LINK]: (children, { href, target, linktype, story }) => {
            let url = href;

            if (linktype === 'email') {
              // Email links: add `mailto:` scheme and map to <a>
              return (
                <a href={`mailto:${href}`} className="text-gradient">
                  {children}
                </a>
              );
            }

            if (linktype === 'story' && story)
              url = rewriteUrl({ linktype, cached_url: story.full_slug });

            return (
              <a href={url} target={target} className="text-gradient">
                {children}
              </a>
            );
          },
        },
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children) => (
            <p className={className}>{children}</p>
          ),
          [NODE_UL]: (children) => (
            <ul className="list-disc list-inside">{children}</ul>
          ),
          [NODE_HEADING]: (children, { level }) => {
            const Tag = headingLevels[level - 1];

            return <Tag>{children}</Tag>;
          },
        },
        defaultBlokResolver: (name, props) => {
          return <DynamicComponent blok={{ component: name, ...props }} />;
        },
      })}
    </div>
  );
};

export default RichText;
