import ContactPage from '../components/ContactPage';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import React from 'react';
import useStoryblok from '../utils/storyblok';

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location);

  const space = pageContext.space;

  const slug = story.full_slug;

  return (
    <>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/808d27dcdb.js"
          defer
          crossOrigin="anonymous"
        />
      </Helmet>
      <Layout location={location}>
        <ContactPage
          story={story}
          space={space}
          location={location}
          slug={slug}
        />
      </Layout>
    </>
  );
}
