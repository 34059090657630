import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Page from '../components/ContactPage';
import React from 'react';
import useStoryblok from '../utils/storyblok';

export default function Page404({ space, location, slug, story }) {
  // Run hook to get story dynamically
  story = useStoryblok(null, location);

  if (story) {
    return (
      <>
        <Helmet>
          <script
            src="https://kit.fontawesome.com/808d27dcdb.js"
            defer
            crossOrigin="anonymous"
          />
        </Helmet>
        <Layout location={location}>
          <Page
            story={story}
            space={space}
            location={location}
            slug={slug}
          ></Page>
        </Layout>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>BrightSign - Page Not Found</title>
        <meta name="description" content="Page not found." />
        <meta name="author" content="BrightSign Technology Limited" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#eb5177" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <Layout location={location}>
        <div className="flex flex-row items-center justify-center w-full h-screen">
          <h1 className="transition duration-1000 transform text-9xl text-gradient hover:scale-105">
            404
          </h1>
        </div>
      </Layout>
    </>
  );
}
