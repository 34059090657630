const rewriteSlug = require('./src/utils/rewriteSlug');

module.exports = {
  siteMetadata: {
    siteUrl: process.env.URL || `https://contacts.brightsignglove.com/`,
  },
  plugins: [
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    'gatsby-plugin-postcss',
    'gatsby-plugin-react-helmet',
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: '43ip5mQMd3DTzI8TFNrE8wtt',
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveRelations: [],
        resolveLinks: 'url',
      },
    },
    {
      resolve: `gatsby-plugin-google-fonts-with-attributes`,
      options: {
        fonts: [
          `Open Sans:600,700`,
          `Roboto:300,400,400i,700`,
          `Cairo:300,400,400i,600,700`,
        ],
        display: 'swap',
        attributes: {
          rel: 'stylesheet preload prefetch',
        },
      },
    },
    {
      resolve: `gatsby-plugin-i18n`,
      options: {
        langKeyDefault: 'en',
        langKeyForNull: 'en',
        prefixDefault: false,
        useLangKeyLayout: false,
      },
    },
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
        query: `
          {
            allPages: allStoryblokEntry(
              filter: { field_component: { in: ["contact"] } }
            ) {
              edges {
                node {
                  id
                  published_at
                  full_slug
                }
              }
            }
          }
        `,
        mapping: {
          allPages: {
            sitemap: 'pages',
            serializer: (edges) => {
              const entries = edges.map((edge) => ({
                node: {
                  id: edge.node.id,
                  slug: rewriteSlug(edge.node.full_slug),
                  updated_at: edge.node.published_at,
                },
              }));
              return entries;
            },
          },
        },
        exclude: [`/404`],
        createLinkInHead: true,
      },
    },
  ],
};
