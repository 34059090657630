import Link from './Link';
import LinkBlock from './LinkBlock';
import Placeholder from './Placeholder';
import React from 'react';

const Components = {
  link: Link,
  link_block: LinkBlock,
};

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component];
    return <Component blok={blok} key={blok._uid} />;
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null;
};

export default DynamicComponent;
