import DynamicComponent from './DynamicComponent';
import { Helmet } from 'react-helmet';
import Picture from './Picture';
import React from 'react';
import RichText from './RichText';
import SbEditable from 'storyblok-react';
import useStoryblok from '../utils/storyblok';

const ContactPage = ({ space, location, slug, story }) => {
  // Run hook to get story dynamically
  story = useStoryblok(story, location);

  const blok = story.content;

  const link_blocks =
    blok.link_blocks &&
    blok.link_blocks.map((childBlok) => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ));

  const footer = blok.footer?.content && (
    <DynamicComponent blok={blok.footer.content} />
  );

  const meta = (
    <Helmet>
      <title>{blok.seo_title || 'BrightSign'}</title>
      <meta name="description" content={blok.seo_description} />
      <meta name="keywords" content={blok.seo_keywords} />
      <meta name="author" content="BrightSign Technology Limited" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#eb5177" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:title" content={blok.seo_title || 'BrightSign'} />
      <meta property="og:description" content={blok.seo_description} />
      <meta property="og:type" content="website" />

      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/round-flag-icons/css/round-flag-icons.min.css"
      />

      {space?.language_codes.map((language_code, language_code_idx) => {
        const language_regex = RegExp(
          `(${space.language_codes.filter((code) => code !== '').join('|')})/`
        );
        const language_code_string =
          language_code === '' || language_code === 'en'
            ? ''
            : `${language_code}/`;

        let full_slug = slug.replace('home', '');
        full_slug = full_slug.replace(language_regex, '');
        full_slug = `${language_code_string}${full_slug}`;

        return (
          <link
            rel="alternate"
            href={`${space.domain}${full_slug}`}
            hrefLang={!language_code.length ? 'x-default' : language_code}
            key={language_code_idx}
          />
        );
      })}
    </Helmet>
  );

  return (
    <SbEditable content={blok} key={blok._uid} story={story}>
      {meta}
      <div className="flex flex-row items-center p-4 space-s-4">
        {blok.image?.filename && (
          <Picture
            src={blok.image.filename}
            width={96}
            height={96}
            alt={blok.image.alt}
            className="w-24 h-24 rounded-full"
          />
        )}
        <div className="flex flex-col space-y-2">
          <h1 className="m-0 text-5xl text-white">{blok.name}</h1>
          <span className="m-0 text-xl font-semibold text-white font-display">
            {blok.title}
          </span>
        </div>
      </div>
      {blok.vcard?.filename && (
        <a
          className="flex flex-row items-center justify-center p-4 m-4 bg-white bg-opacity-30 rounded-2xl space-s-4 transform hover:scale-105 transition duration-500 hover:shadow-xl"
          href={blok.vcard.filename}
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-xl text-white fas fa-arrow-down"></span>
          <span className="font-semibold text-white font-display">
            Download Contact
          </span>
        </a>
      )}
      <div className="p-4 m-4 bg-white rounded-2xl">
        <RichText document={blok.bio} className="text-black"></RichText>
      </div>
      <div className="flex flex-col p-4 space-y-4">{link_blocks}</div>
      {footer}
    </SbEditable>
  );
};

export default ContactPage;
