import React from 'react';
import rewriteUrl from '../utils/rewriteUrl';

const Link = ({ blok }) => {
  const url = rewriteUrl(blok.url);

  return (
    <a
      href={url}
      className="flex flex-row items-center p-4 bg-white space-s-4 rounded-2xl group"
    >
      <span
        aria-hidden
        className={`${blok.icon} text-2xl ${
          blok.icon.includes('round-flag-icon') ? '' : 'text-gradient'
        } w-6 h-6 flex flex-row items-center justify-center transform group-hover:scale-110 transition duration-300`}
      ></span>
      <span className="flex-grow">{blok.name}</span>
      <span
        aria-hidden
        className="text-xl text-gray-300 group-hover:text-gray-400 fas fa-chevron-right transition"
      ></span>
    </a>
  );
};

export default Link;
